<template>
  <b-form name="perfect-mind-form" id="perfect-mind-form" autocomplete="off" @submit.prevent="onSubmitPerfectMindForm" novalidate>
    <b-form-row v-for="(input, index) in formInputList" :key="index">
      <b-col cols="12" v-if="input.key == 'PerfectMind-APIKey' && isEditMode">
        <b-form-group>
          <b-form-checkbox v-model="updateAPIKeyChecked" @change="updateAPIKey" name="update-api-key-checked"> Update API key </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col cols="12" v-if="input.key === 'PerfectMind-LocationFilter'">
        <b-form-group>
          Citizens can filter program and activities by location. Please enter the city name(s) for which the locations should be shown in the location filter.
          Separate multiple cities with comma (,)
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <div class="floating-input-field">
            <b-form-input
              v-if="input.isStored && !updateAPIKeyChecked && input.key == 'PerfectMind-APIKey'"
              :id="input.key"
              type="password"
              value="************"
              placeholder=" "
              autocomplete="new-password"
              disabled
            >
            </b-form-input>
            <b-form-input
              v-else
              :id="input.key"
              :type="input.key == 'PerfectMind-APIKey' ? 'password' : 'text'"
              placeholder=" "
              v-model.trim="input.value"
              :autocomplete="input.key == 'PerfectMind-APIKey' ? 'new-password' : 'off'"
              required
            ></b-form-input>
            <label :for="input.key">{{ input.label }}</label>
            <b-form-invalid-feedback class="d-block" v-if="!input.value && !input.isStored && input.required && formSubmitted"
              >{{ input.label }} required.</b-form-invalid-feedback
            >
            <b-form-invalid-feedback class="d-block" v-if="!input.value && updateAPIKeyChecked && formSubmitted && input.key == 'PerfectMind-APIKey'"
              >{{ input.label }} required.</b-form-invalid-feedback
            >
          </div>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group>
          <b-button type="submit" v-activeBlur variant="primary" :disabled="saveLoadingIcon" @click="perfectMindSidebarFg = true"
            >Save<b-spinner v-if="saveLoadingIcon" label="Spinning" small class="ml-2"></b-spinner>
          </b-button>
        </b-form-group>
      </b-col>
    </b-form-row>
  </b-form>
</template>
<script>
import axios from 'axios'
import intersectionBy from 'lodash/intersectionBy'
export default {
  name: 'PerfectMindForm',
  props: ['tenantServiceId'],
  data() {
    return {
      getPerfectMindConfigurationURL: process.env.VUE_APP_GET_TENANT_SETTINGS,
      updatePerfectMindConfigurationURL: process.env.VUE_APP_UPDATE_TENANT_SETTINGS,
      apiHeaders: null,
      formSubmitted: false,
      saveLoadingIcon: false,
      isValidConfiguration: false,
      updateAPIKeyChecked: false,
      isEditMode: false,
      savedConfigurations: [],
      formInputList: [
        {
          id: null,
          label: 'Base domain',
          key: 'PerfectMind-BaseDomain',
          value: '',
          isStored: false,
          required: true,
        },
        {
          id: null,
          label: 'API version',
          key: 'PerfectMind-APIVersion',
          value: '',
          isStored: false,
          required: true,
        },
        {
          id: null,
          label: 'Client id',
          key: 'PerfectMind-ClientId',
          value: '',
          isStored: false,
          required: true,
        },
        {
          id: null,
          label: 'API Key',
          key: 'PerfectMind-APIKey',
          value: '',
          isStored: false,
          required: true,
        },
        {
          id: null,
          label: 'City name(s)',
          key: 'PerfectMind-LocationFilter',
          value: '',
          isStored: false,
          required: false,
        },
      ],
    }
  },
  mounted() {
    this.updateHeaders().then(() => this.getPerfectMindConfiguration())
  },
  methods: {
    updateHeaders() {
      return new Promise((resolve, reject) => {
        this.apiHeaders = {
          headers: {
            'x-request-id': Date.now(),
            'x-product-id': 'PerfectMind',
            'x-tenant-id': this.tenantServiceId,
            'Ocp-Apim-Subscription-Key': process.env.VUE_APP_APIM_KEY,
          },
        }
        resolve()
      })
    },
    assignDefaultValue() {
      this.isEditMode = false
      this.formInputList.forEach((input) => {
        input.tenantId = this.tenantServiceId
        input.value = ''
        input.isStored = false
      })
    },
    getPerfectMindConfiguration() {
      return axios
        .get(this.getPerfectMindConfigurationURL, this.apiHeaders)
        .then((res) => {
          this.updateAPIKeyChecked = false
          if (res.data && res.data.data && res.data.data.length) {
            this.isEditMode = true
            this.savedConfigurations = res.data.data.slice()
            this.formInputList.forEach((input) => {
              input.tenantId = this.tenantServiceId
              const inputObject = res.data.data.find((data) => data.key === input.key)
              if (inputObject) {
                input.value = inputObject.value
                input.isStored = inputObject.isStored
              }
            })
          } else {
            this.assignDefaultValue()
          }
          this.isValidConfiguration = !this.formInputList.filter((input) => !input.isStored && input.required && !input.value).length
          this.$emit('perfectMindIsValidConfiguration', this.isValidConfiguration)
          return res
        })
        .catch((error) => {
          this.assignDefaultValue()
          this.updateAPIKeyChecked = false
          this.$emit('perfectMindIsValidConfiguration', false)
          return Promise.reject(error)
        })
    },
    updateAPIKey() {
      const apiKeyIndex = this.formInputList.findIndex((input) => input.key === 'PerfectMind-APIKey')
      this.formInputList[apiKeyIndex].value = this.savedConfigurations[apiKeyIndex].value
    },
    validateForm() {
      return !this.formInputList.some((input) => {
        if (input.required) {
          return this.updateAPIKeyChecked ? !input.value : !input.isStored && !input.value
        } else {
          return false
        }
      })
    },
    updatePerfectMindConfiguration(payload) {
      return axios
        .post(this.updatePerfectMindConfigurationURL, payload, this.apiHeaders)
        .then((res) => {
          this.saveLoadingIcon = false
          if (res.data && res.data.data) {
            this.getPerfectMindConfiguration()
          }
          this.$emit('perfectMindConfigUpdateRes', res.data)
          return res
        })
        .catch((error) => {
          this.saveLoadingIcon = false
          this.$emit('perfectMindConfigUpdateRes', false)
          return Promise.reject(error)
        })
    },
    async onSubmitPerfectMindForm() {
      this.formSubmitted = true
      if (this.validateForm()) {
        this.saveLoadingIcon = true
        const updatePayload = this.formInputList.filter((input) => input.value || input.key === 'PerfectMind-APIKey')
        const deleteKeys = this.formInputList.filter((input) => !input.value && input.key !== 'PerfectMind-APIKey')
        const deletePayload = intersectionBy(deleteKeys, this.savedConfigurations, 'key').map((item) => item.key)
        if (deletePayload.length) {
          return await axios
            .delete(this.updatePerfectMindConfigurationURL, { headers: this.apiHeaders.headers, data: deletePayload })
            .then((res) => {
              if (res.data && res.data.data) {
                this.updatePerfectMindConfiguration(updatePayload)
              }
              return res
            })
            .catch((error) => {
              this.saveLoadingIcon = false
              this.$emit('perfectMindConfigUpdateRes', false)
              return Promise.reject(error)
            })
        } else {
          this.updatePerfectMindConfiguration(updatePayload)
        }
      }
    },
  },
  watch: {
    tenantServiceId(newValue, oldValue) {
      this.updateHeaders().then(() => this.getPerfectMindConfiguration())
    },
  },
}
</script>
